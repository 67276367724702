<template>
  <v-card elevation="0" outlined>
    <v-card-subtitle>{{ title }}</v-card-subtitle>
    <v-card-text>
      <v-row dense>
        <v-col class="d-flex justify-center">
          <v-img :src="lazySrc" contain max-width="100%" v-if="!proofItem"></v-img>
          <img width="100%" ref="img" v-if="proofItem" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import vs from '@/utils/viewSupport'
import api from '@/services/api'
export default {
  name: 'Proof',
  props: {
    lazySrc: { type: String, default: null },
    proofItem: { type: Object, default: () => { } }
  },
  data() {
    return {
      vs,
      imgSrc: null
    }
  },
  methods: {
    getImageContent() {
      if (this.proofItem._id) {
        api.getProofImageBase64ByProofId(this.proofItem._id).then(r => {
          this.$refs.img.src = r
          // this.$refs.img.width = 200
          // this.$refs.img.height = 100
        })
      }
    }
  },
  mounted() {
    this.getImageContent()
  },
  computed: {
    title() {
      if (this.proofItem) {
        let t = vs.renderProofType(this.proofItem.proofType) + ' '
        t += vs.renderIsAfter(this.proofItem.isAfter) + ' '
        t += vs.renderViewpoint(this.proofItem.viewpoint)
        return t
      } else {
        return ''
      }
    },
  },
  watch: {
    proofItem() {
      this.getImageContent()
    }
  }
}
</script>
